import { createAction } from '@reduxjs/toolkit'
import { ActionTypes_Bookings } from './TActions'
import { TFutureBooking } from './TReducer'
import { TStoreTemplate } from 'redux/config/root-types'
import { ISagaWorker_GetBookingInvoice } from 'redux/sagas/events/bookings/SagaWorker_GetBookingInvoice'

const { StoreActions, SagaEvents } = ActionTypes_Bookings

export const Actions_Bookings = {
  saga: {
    loadPage: createAction(SagaEvents.LOAD_PAGE),
    getBookingInvoice: createAction<ISagaWorker_GetBookingInvoice>(SagaEvents.GET_BOOKING_INVOICE),
  },

  store: {
    showLoader: createAction(StoreActions.IS_LOADER_SHOW),
    hideLoader: createAction(StoreActions.IS_LOADER_HIDE),
    saveBookings: createAction<TStoreTemplate<TFutureBooking[]>>(StoreActions.SAVE_BOOKINGS),
    //filterTrackerCodes: createAction<number>(StoreActions.FILTER_TRACKER_CODES),
    reset: createAction(StoreActions.RESET),
  },
}
