enum StoreActions {
  IS_LOADER_SHOW = 'BOOKINGS/IS_LOADER_SHOW',
  IS_LOADER_HIDE = 'BOOKINGS/IS_LOADER_HIDE',
  SAVE_BOOKINGS = 'BOOKINGS/SAVE_BOOKINGS',
  FILTER_BOOKINGS = 'BOOKINGS/FILTER_BOOKINGS',
  RESET = 'BOOKINGS/RESET',
}

enum SagaEvents {
  LOAD_PAGE = 'BOOKINGS/LOAD_PAGE',
  GET_BOOKING_INVOICE = 'BOOKINGS/GET_BOOKING_INVOICE',
}

export const ActionTypes_Bookings = {
  SagaEvents,
  StoreActions,
}
