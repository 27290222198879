import { createReducer } from '@reduxjs/toolkit'
import { Actions_Bookings } from './Actions'
import { IStateBookings } from './TReducer'

const initialState: IStateBookings = {
  isLoading: false,
  data: null,
  responseInfo: null,
}

const ReducerTrackerCodes = createReducer(initialState, (builder) => {
  builder
    .addCase(Actions_Bookings.store.showLoader, (state) => {
      state.isLoading = true
    })
    .addCase(Actions_Bookings.store.hideLoader, (state) => {
      state.isLoading = false
    })
    .addCase(Actions_Bookings.store.saveBookings, (state, action) => {
      const payload = action.payload
      return { ...state, ...payload }
    })
    // .addCase(Actions_Bookings.store.filterTrackerCodes, (state, action) => {
    //   const payload = action.payload
    //   return { ...state, data: state.data ? state.data?.filter((item) => item.id !== payload) : null }
    // })
    // .addCase(Actions_Bookings.store.unshiftToTrackerCodes, (state, action) => {
    //   const payload = action.payload

    //   if (!state.data) {
    //     return { ...state, data: [payload] }
    //   }

    //   const newData = [...state.data, payload]

    //   return { ...state, data: newData }
    // })
    .addCase(Actions_Bookings.store.reset, () => {
      return initialState
    })
})

export default ReducerTrackerCodes
