import React from 'react'
import { Route, Navigate, Routes } from 'react-router-dom'
import { RootComponent } from 'ui/root-components'
import { To } from 'router/To'

type TProps = {
  isAuthentificated: boolean
  authentificatedRoute: string
  isInitialized: boolean
  rules: {
    canTrailerView?: boolean
    canTrailerListView?: boolean
    canTrailerDetailsView?: boolean
    canTrailerTypeView?: boolean
    canStaffView?: boolean
    canStaffDetailsView?: boolean
    canOrganizationView?: boolean
    canParentOrganizationView?: boolean
    canOrganizationDetailsView?: boolean
    canParkingView?: boolean
    canParkingShowListView?: boolean
    canParkingDetailsView?: boolean
    canPriceView?: boolean
    canInspectionView?: boolean
    canReportView?: boolean
    canUserView?: boolean
    canUserDetailsView?: boolean
    canPromocodeView?: boolean
    canTrackerCodesView?: boolean
  }
}

export const useRoutes = ({
  isAuthentificated,
  authentificatedRoute,
  isInitialized,
  rules: {
    canTrailerView,
    canTrailerListView,
    canTrailerDetailsView,
    canTrailerTypeView,
    canStaffView,
    canStaffDetailsView,
    canOrganizationView,
    canOrganizationDetailsView,
    canParentOrganizationView,
    canParkingView,
    canParkingDetailsView,
    canParkingShowListView,
    canPriceView,
    canInspectionView,
    canReportView,
    canUserView,
    canUserDetailsView,
    canPromocodeView,
    canTrackerCodesView,
  },
}: TProps): JSX.Element => {
  if (isAuthentificated) {
    if (isInitialized) {
      return (
        <RootComponent.StaticControls>
          <RootComponent.PusherGlobalEvents />
          <Routes>
            {canTrailerView && <Route path={To.trailersMap} element={<_TrailersMap />} />}
            {canTrailerListView && <Route path={To.trailersList} element={<_TrailersList />} />}
            {canTrailerDetailsView && <Route path={`${To.trailerDetails}/:id`} element={<_TrailerDetails />} />}
            {canTrailerTypeView && <Route path={To.trailerTypes} element={<_TrailerTypes />} />}
            {canReportView && <Route path={To.reports} element={<_Reports />} />}
            {canParkingView && <Route path={To.parkings} element={<_Parkings />} />}
            {canParkingDetailsView && <Route path={`${To.parkingDetails}/:id`} element={<_ParkingDetails />} />}
            {canParkingShowListView && (
              <Route path={`${To.parkingShowListTable}`} element={<_ParkingShowListTable />} />
            )}
            {canOrganizationView && <Route path={To.organizations} element={<_Organizations />} />}
            {canOrganizationDetailsView && (
              <Route path={`${To.organizationDetails}/:id`} element={<_OrganizationDetails />} />
            )}
            {canParentOrganizationView && <Route path={To.parentOrganizations} element={<_ParentOrganizations />} />}
            {canStaffView && <Route path={To.staff} element={<_Staff />} />}
            {canStaffDetailsView && <Route path={`${To.staffDetails}/:id`} element={<_StaffDetails />} />}
            {canUserView && <Route path={To.users} element={<_Users />} />}
            {canUserDetailsView && <Route path={`${To.userDetails}/:id`} element={<_UserDetails />} />}
            {canPromocodeView && <Route path={To.promoCode} element={<_PromoCode />} />}
            {canInspectionView && <Route path={To.inspection} element={<_Inspection />} />}
            {canTrackerCodesView && <Route path={To.trackerCodes} element={<_TrackerCodes />} />}
            <Route path={To.bookings} element={<_Bookings />} />
            <Route path="*" element={<Navigate to={authentificatedRoute} />} />
          </Routes>
        </RootComponent.StaticControls>
      )
    } else {
      return <></>
    }
  }
  return (
    <Routes>
      <Route path="/" element={<_Auth />} />
      <Route path={To.changePassword} element={<_ChangePassword />} />
      <Route path="*" element={<Navigate to={'/'} />} />
    </Routes>
  )
}

const _TrailerDetails = React.lazy(() => import('../ui/pages/TrailerDetails/TrailerDetails'))
const _TrailersMap = React.lazy(() => import('../ui/pages/TrailersMap/TrailersMap'))
const _TrailersList = React.lazy(() => import('../ui/pages/TrailersList/TrailersList'))
const _Auth = React.lazy(() => import('../ui/pages/Auth/Auth'))
const _TrailerTypes = React.lazy(() => import('../ui/pages/TrailerTypes/TrailerTypes'))
const _Reports = React.lazy(() => import('../ui/pages/Reports/Reports'))
const _Parkings = React.lazy(() => import('ui/pages/Parkings/Parkings'))
const _ParkingDetails = React.lazy(() => import('ui/pages/ParkingDetails/ParkingDetails'))
const _ParkingShowListTable = React.lazy(() => import('ui/pages/ParkingShowList/ParkingShowList'))
const _Organizations = React.lazy(() => import('ui/pages/Organizations/Organizations'))
const _OrganizationDetails = React.lazy(() => import('ui/pages/OrganizationDetails/OrganizationDetails'))
const _Staff = React.lazy(() => import('ui/pages/Staff/Staff'))
const _StaffDetails = React.lazy(() => import('../ui/pages/StaffDetails/StaffDetails'))
const _ChangePassword = React.lazy(() => import('../ui/pages/ChangePassword/ChangePassword'))
const _Users = React.lazy(() => import('../ui/pages/Users/Users'))
const _UserDetails = React.lazy(() => import('../ui/pages/UserDetails/UserDetails'))
const _PromoCode = React.lazy(() => import('../ui/pages/PromoCode/PromoCode'))
const _Inspection = React.lazy(() => import('ui/pages/Inspection/Inspection'))
const _ParentOrganizations = React.lazy(() => import('ui/pages/ParentOrganizations/ParentOrganizations'))
const _TrackerCodes = React.lazy(() => import('ui/pages/TrackerCodes/TrackerCodes'))
const _Bookings = React.lazy(() => import('ui/pages/Bookings/Bookings'))
